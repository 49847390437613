import {readFileSync} from 'fs';

export const customCSS = readFileSync("assets/css/fil.css", "utf8");
export const mainCSS = readFileSync("assets/css/main.css", "utf8");
export const alertifyCSS = readFileSync("assets/css/alertify.css", "utf8");

export const embedCSS = readFileSync("assets/css/embed.css", "utf8");

export const faCSS = readFileSync("assets/css/fontawesome-all.min.css", "utf8");
const mapMarkerBData = Buffer(readFileSync("images/map-markerB.png")).toString('base64');
export const mapMarkerB = `data:image/png;base64,${mapMarkerBData}`;

import { icon } from '@fortawesome/fontawesome-svg-core'
import { faFlag, faThumbsUp, faLink, faDownload } from '@fortawesome/free-solid-svg-icons'
export const icons = {
  flag: icon(faFlag),
  thumbsUp: icon(faThumbsUp),
  link: icon(faLink),
  download: icon(faDownload)
}


